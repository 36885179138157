<template>
  <div class="max">
    <!-- 左侧部分 -->
    <div class="left">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <div class="left_shaixuan">
          <!-- 选择时间 -->
          <el-form-item prop="value2" class="time">
            <el-date-picker
              class="riqi"
              v-model="ruleForm.value2"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <!-- 对话线索 -->

          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >对话线索</el-checkbox>
          <div style="margin: 5px 0;"></div>
          <el-form-item prop="checkedCities" class="time">
            <el-checkbox-group v-model="ruleForm.checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox class="duihua" v-for="city in cities" :label="city" :key="city">
                {{
                city
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 对话类型 -->

          <el-select v-model="value" class="leixin" placeholder="对话类型" @change="xuan(value)">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-form-item prop="checkedCities1" class="time">
            <el-checkbox-group v-model="ruleForm.checkedCities1">
              <el-checkbox class="duihua" v-for="city1 in cities1" :label="city1" :key="city1">
                {{ city1
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 访问来源 -->

          <el-form-item label="访问来源" effect="dark" prop="laiyuan" class="mgbo">
            <el-tooltip
              class="item"
              effect="dark"
              content="访客通过各种渠道进入网站,主要包括外部链接、搜索引擎、直接访问"
              placement="bottom-start"
            >
              <i class="el-icon-info laiyuan"></i>
            </el-tooltip>
            <el-input v-model="ruleForm.laiyuan"></el-input>
          </el-form-item>
          <el-form-item label="搜索引擎" prop="suoyou" class="mgbo">
            <el-select class="yinqin" v-model="ruleForm.suoyou" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="搜索词" prop="type" class="mgbo">
            <el-tooltip
              class="item"
              effect="dark"
              content="搜索词或推广链接设置的关键词"
              placement="bottom-start"
            >
              <i class="el-icon-info ssc"></i>
            </el-tooltip>
            <el-input v-model="ruleForm.type"></el-input>
          </el-form-item> -->
        </div>
        <div class="left_dianji">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">开始筛选</el-button>
            <el-button class="chongzhi" @click="resetForm('ruleForm')">清除筛选</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 右侧部分 -->
    <div class="right">
      <!-- <div class="right_top">
        <el-row>
          <el-button type="text" @click="dialogVisible = true" class="xianshi" plain>显示字段设置</el-button>
        </el-row>
        <el-dialog class="ziduan" title="设置显示字段" :visible.sync="dialogVisible" width="380px">
          <el-form ref="formziduan" :model="formziduan">
            <el-checkbox-group v-model="formziduan.checkList">
              <ul>
                <li>
                  <el-checkbox label="访客名称" disabled></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="接待客服" disabled></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客标签">
                    访客标签
                    <el-tooltip class="item" effect="dark" content="客户对该访客的归类" placement="bottom">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话时间"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="开始方式">
                    开始方式
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="建立对话的方式,包括主动发起、客服图标、微信、固定链接APP、其他"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="结束方式"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话数(客：访)"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访问来源">
                    访问来源
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="访客通过各种渠道进入网站,主要包括外部链接、搜索引擎、直接访问"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="搜索词">
                    搜索词
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="搜索词或推广链接设置的关键词"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="投放词">
                    投放词
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="投放广告时的关键词(当前仅百度、搜狗支持)"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="广告主id"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="着陆页">
                    着陆页
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="访客到达的第一个页面。访客点击广告或者搜索引擎结果后显示给访客的网页"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="推广链接"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="咨询页">
                    咨询页
                    <el-tooltip class="item" effect="dark" content="建立对话页面" placement="bottom">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <el-checkbox label="风格">
                    风格
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="来自不同的 53KF 挂码地址"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客地区"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客IP"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话渠道">
                    对话渠道
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="包括PC、移动(不含微信)、微信"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="消息类型">
                    消息类型
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="包括在线消息、留言消息、机器人消息"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话主题">
                    对话主题
                    <el-tooltip class="item" effect="dark" content="客服对该此对话的归类" placement="bottom">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="首次响应时长"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="平均响应时长"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话时长"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客评价"></el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客编号"></el-checkbox>
                </li>
              </ul>
            </el-checkbox-group>
            <el-form-item class="tianjiaziduan">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="sumbi('formziduan')">确 定</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <el-row class="dell">
          <el-button :disabled="xiazai">下载</el-button>
          <el-button :disabled="xiazai">添加访客标签</el-button>
          <el-button :disabled="xiazai">修改主题</el-button>
          <el-button :disabled="xiazai">回复</el-button>
          <el-button :disabled="xiazai">标记已处理</el-button>
          <el-button :disabled="xiazai">删除</el-button>
        </el-row>
      </div> -->
      <!-- 表单数据 -->
      <div class="biaodan">
        <el-dialog :title="name" :visible.sync="dialogVisible1" width="50%">
          <!-- 消息内容 -->
          <div class="xxnr" ref="miaoscoll">
            <ul>
              <li
                v-for="(val, index) in liaotianjil"
                :key="index"
                :class="val.role == 'user' ? 'left_yk' : 'right_kf'"
              >
              <p  v-if="val.type!=2">{{val.createtime}}</p>
              <span v-if="val.type ==0">{{ val.content }}</span>
              <span v-else-if="val.type==1" class="imgwidth"><img :src="'http://chat.xuekao123.com'+val.content" alt="" @click="handleMessageClick(val.content)"></span>
            </li>
            </ul>
          </div>
        </el-dialog>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>

          <!-- <el-table-column v-for="(item,index) in columns" :key="index" :label="item.label" :prop="item.value" >
          </el-table-column>-->

          <el-table-column prop="visitor_id" label="访客ID" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="visitor_name" label="访客名称" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="admin_id" label="接待客服" show-overflow-tooltip></el-table-column>
          <el-table-column prop="phone" label="访客固定电话" show-overflow-tooltip></el-table-column>
          <el-table-column prop="mobile" label="访客手机号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="email" label="访客邮箱" show-overflow-tooltip></el-table-column>
          <el-table-column prop="qq" label="访客QQ" show-overflow-tooltip></el-table-column>
          <el-table-column label="对话时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createtime }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">查看聊天记录</el-button>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="duihua" label="对话数(客：访)" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="url" label="" show-overflow-tooltip>
                        <template slot="header">
                            访问来源
                            <el-tooltip class="item" effect="dark" content="访客通过各种渠道进入网站,主要包括外部链接、搜索引擎、直接访问"
                                placement="bottom">
                                <i class="el-icon-info biaotou1"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ssc" label="" show-overflow-tooltip>
                        <template slot="header">
                            搜索词
                            <el-tooltip class="item" effect="dark" content="搜索词或推广链接设置的关键词" placement="bottom">
                                <i class="el-icon-info biaotou"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zxy" label="" show-overflow-tooltip>
                        <template slot="header">
                            咨询页
                            <el-tooltip class="item" effect="dark" content="建立对话页面" placement="bottom">
                                <i class="el-icon-info biaotou"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dq" label="地区" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="ip" label="访客IP" show-overflow-tooltip>
          </el-table-column>-->
        </el-table>
      </div>
      <!-- 页码 -->
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          :total="sumye"
          :page-sizes="[5, 10, 30, 50, 100]"
          :page-size="Barpage"
          @current-change="yema"
          @size-change="tiaoshu"
        ></el-pagination>
      </div>
    </div>
    <!-- 图片弹窗 -->
    <el-dialog
      :visible.sync="imgDialog"
      :before-close="handleImgClose"
      top="100px"
      class="imgpopup"
    >
      <img :src="imgTcUrl" class="videoTc" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { ltlist } from "@/api/jiekou";
import Axios from "axios";

const cityOptions = ["手机", "QQ", "邮箱", "固话"];
export default {
  name: "XianmuFkmd",

  data() {
    return {
      ruleForm: {
        value2: "", //选时间
        checkedCities: [], //对话线索
        checkedCities1: [], //消息类型
        laiyuan: "",
        suoyou: "", //搜索引擎
        type: ""
      },

      checkAll: false,
      cities: cityOptions,
      cities1: ["人工", "机器人", "留言"],

      isIndeterminate: false,
      dialogVisible: false, //显示字段设置
      dialogVisible1: false, //显示聊天记录
      name: "", //点击查看聊天记录显示游客名称
      // 选中字段
      formziduan: {
        checkList: ["访客名称", "接待客服"]
      },

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      //消息类型
      options: [
        {
          value: "选项1",
          label: "对话类型"
        },
        {
          value: "选项2",
          label: "消息类型"
        }
      ],
      value: "对话类型",
      //搜索引擎
      options1: [
        {
          value: "选项1",
          label: "所有引擎"
        },
        {
          value: "选项2",
          label: "百度"
        },
        {
          value: "选项3",
          label: "搜狗"
        },
        {
          value: "选项4",
          label: "360"
        },
        {
          value: "选项5",
          label: "Google"
        },
        {
          value: "选项6",
          label: "神马"
        },
        {
          value: "选项7",
          label: "新浪"
        },
        {
          value: "选项8",
          label: "雅虎"
        },
        {
          value: "选项9",
          label: "Bing"
        },
        {
          value: "选项10",
          label: "今日头条"
        }
      ],
      rules: {},

      xiazai: true,
      //用户表单
      tableData: [],
      multipleSelection: [],
      currentPage4: 4,
      sumye: 0, //总页
      Barpage: 5, //条/页
      liaotianjil: [], //存放聊天记录
      pagination: {}, //筛选后的页码
      imgDialog: false,
      imgTcUrl:""
    };
  },

  mounted() {
    this.liaotian();
    this.tiaoshu();
  },

  methods: {
    // 开始筛选
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // console.log(_this.ruleForm);
          let time = "";
          let time1 = "";
          if (_this.ruleForm.value2) {
            time = _this.timeriqi(_this.ruleForm.value2[0]) + " " + "00:00";
            time1 = _this.timeriqi(_this.ruleForm.value2[1]) + " " + "24:00";
          }
          let duihua = [];
          if (_this.ruleForm.checkedCities) {
            _this.ruleForm.checkedCities.forEach(val => {
              if (val == "手机") {
                duihua.push("mobile");
              }
              if (val == "QQ") {
                duihua.push("qq");
              }
              if (val == "邮箱") {
                duihua.push("email");
              }
              if (val == "固话") {
                duihua.push("phone");
              }
            });
          }
          let leixin = [];
          if (_this.ruleForm.checkedCities1) {
            _this.ruleForm.checkedCities1.forEach(val => {
              if (val == "人工") {
                leixin.push("1");
              }
              if (val == "机器人") {
                leixin.push("2");
              }
              if (val == "留言") {
                leixin.push("3");
              }
            });
          }
          let res = await ltlist({
            createtime: [time, time1],
            url: _this.ruleForm.laiyuan,
            type: leixin,
            clue: duihua
          });
          if (res.data.code == 200) {
            _this.pagination = {
              createtime: [time, time1],
              url: _this.ruleForm.laiyuan,
              type: leixin,
              clue: duihua
            };
            // console.log(res.data.data);
            this.sumye = res.data.data.count;
            // console.log(_this.tableData);
            _this.tableData = [];
            res.data.data.data.forEach(value => {
              let arr = {};
              arr.visitor_id = value.visitor_id;
              arr.visitor_name = value.visitor.visitor_name;
              arr.admin_id = value.admin.username;
              arr.mobile=value.visitor.mobile
              arr.phone=value.visitor.phone
              arr.email=value.visitor.email
              arr.qq=value.visitor.qq
              arr.createtime = _this.timestampToTime(value.createtime);
              arr.ltjl = value.content.content;
              arr.ltjl.forEach(val=>{
          val.createtime=_this.timestampToTime(val.createtime)
        })
              _this.tableData.push(arr);
            });
            // console.log(_this.tableData);
            this.tiaoshu();
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 清除筛选
    resetForm(formName) {
      this.pagination = {};
      this.$refs[formName].resetFields();
      this.liaotian();
      this.tiaoshu();
    },
    handleCheckAllChange(val) {
      this.ruleForm.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      // console.log(value.length);
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    handleCheckAllChange1(val) {
      this.ruleForm.checkedCities1 = val ? this.cityOptions : [];
    },
    //选择对话线索
    xuan(e) {
      if (e == "选项2") {
        this.cities1 = [
          "人工",
          "机器人",
          "智能引导",
          "场景引导",
          "留言",
          "退出挽留",
          "访客表单",
          "通话",
          "互动菜单"
        ];
      } else {
        this.cities1 = ["人工", "机器人", "留言"];
      }
    },
    //表单
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
      if (val.length == 0) {
        this.xiazai = true;
      } else {
        this.xiazai = false;
      }
    },
    // 页码
    async yema(val) {
      let _this = this;
      _this.pagination.limit = _this.Barpage;
      _this.pagination.page = val;
      let res = await ltlist(_this.pagination);
      _this.tableData.length = 0;
      res.data.data.data.forEach(val => {
        let arr = {};
        arr.visitor_id = val.visitor_id;
        arr.visitor_name = val.visitor.visitor_name;
        arr.admin_id = val.admin.username;
        arr.mobile=val.visitor.mobile
              arr.phone=val.visitor.phone
              arr.email=val.visitor.email
              arr.qq=val.visitor.qq
        arr.createtime = _this.timestampToTime(val.createtime);
        arr.ltjl = val.content.content;
        arr.ltjl.forEach(val=>{
          val.createtime=_this.timestampToTime(val.createtime)
        })
        _this.tableData.push(arr);
      });
    },
    // 条/页
    async tiaoshu(val) {
      let _this = this;
      _this.Barpage = val; //条页赋值
      _this.pagination.limit = val;
      let res = await ltlist(_this.pagination);
      _this.tableData.length = 0;
      res.data.data.data.forEach(val => {
        let arr = {};
        arr.visitor_id = val.visitor_id;
        arr.visitor_name = val.visitor.visitor_name;
        arr.admin_id = val.admin.username;
        arr.mobile=val.visitor.mobile
              arr.phone=val.visitor.phone
              arr.email=val.visitor.email
              arr.qq=val.visitor.qq
        arr.createtime = _this.timestampToTime(val.createtime);
        arr.ltjl = val.content.content;
        arr.ltjl.forEach(val=>{
          val.createtime=_this.timestampToTime(val.createtime)
        })
        _this.tableData.push(arr);
      });
    },
    // 聊天列表
    async liaotian() {
      let _this = this;
      let res = await ltlist();
      // console.log(res.data);
      if (res.data.code==-1) {
                alert("你的用户名失效，"+res.data.msg)
                this.$router.replace("/login");
            } else{
          this.sumye = res.data.data.count;
          _this.tableData = [];
          res.data.data.data.forEach(val => {
            let arr = {};
            arr.visitor_id = val.visitor_id;
            arr.visitor_name = val.visitor.visitor_name;
            arr.admin_id = val.admin.username;
            arr.mobile=val.visitor.mobile
              arr.phone=val.visitor.phone
              arr.email=val.visitor.email
              arr.qq=val.visitor.qq
            arr.createtime = _this.timestampToTime(val.createtime);
            arr.ltjl = val.content.content;
            arr.ltjl.forEach(val=>{
              val.createtime=_this.timestampToTime(val.createtime)
            })
            _this.tableData.push(arr);
            
          });
        }
    },

    //添加字段
    sumbi(formziduan) {
      let _this = this;
      this.dialogVisible = false;
      // console.log(this.formziduan.checkList);
      // this.formziduan.checkList = this.formziduan.checkList
      // this.formziduan.checkList.forEach(val => {
      //     let addziduan = {}
      //     // // console.log(val)
      //     addziduan.name = val.split('-')[0]
      //     addziduan.value = val.split('-')[1]

      //     _this.biaotouzi.push(addziduan)
      // })
      // // console.log(_this.biaotouzi)
    },

    // 查看聊天按钮
    handleEdit(index, row) {
      // console.log(index, row);
      this.name = row.visitor_name;
      this.dialogVisible1 = true;
      this.liaotianjil = row.ltjl;
      // console.log(this.liaotianjil); 

      this.$nextTick(()=>{  
        let scrollEl=this.$refs.miaoscoll
        scrollEl.scrollTo({top:scrollEl.scrollHeight,behavior:'auto'})
      })
    },
    // 图片弹窗
    handleImgClose() {
      this.imgDialog = false;
    },
    // 点击图片
    handleMessageClick(val) {
      this.imgTcUrl = "http://chat.xuekao123.com" + val;
      this.imgDialog = true;
    },

    //将时间戳转换成日期格式
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 中国标准时间转换成日期格式
    timeriqi(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return (time = y + "-" + m + "-" + d);
    }
  }
};
</script>

<style lang="less" scoped>
.max {
  display: flex;
}

.left {
  height: 100%;
  min-width: 280px;
}

.left .left_shaixuan {
  height: calc(100vh - 117px);
  width: 100%;
  overflow-y: scroll;
  padding: 0 10px;
}

.left .left_dianji {
  width: 100%;
  height: 55px;
  background-color: #f5f5f5;
}

.riqi {
  width: 100%;
  margin-bottom: 20px;
  padding: 3px 0px;
}

.riqi ::v-deep input {
  font-size: 12px !important;
}

.duihua {
  margin-right: 4px !important;
  margin-bottom: 5px;
}

.leixin {
  width: 35%;
}

.leixin ::v-deep .el-input__inner {
  border: none;
  padding: 0;
}

.fangwen ::v-deep .el-form-item {
  margin-bottom: 0px !important;
}

.fangwen ::v-deep .el-form-item .el-form-item__label {
  padding: 0 !important;
  position: relative;
}

.yinqin {
  width: 100%;
}

.left_dianji ::v-deep .el-form-item__content {
  float: right;
  padding-top: 11px;
  margin-left: 0px !important;
}

.laiyuan {
  position: absolute;
  left: 60px;
  top: -25px;
}

.ssc {
  position: absolute;
  left: 54px;
  top: -25px;
}

.chongzhi {
  background: transparent;
  border: none;
}

.right {
  height: calc(100vh - 70px);
  overflow-y: scroll;
  width: 100%;
  padding: 10px 10px 0 22px;
}

.right .right_top {
  width: 100%;
  display: flex;
}

.right .right_top .xianshi {
  padding: 5px;
  border: 1px solid #d5d8db;
  color: #28334b;
  font-size: 12px;
  border-radius: 0px;
  margin-right: 10px;
}

.right .right_top .xianshi:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.ziduan ::v-deep .el-dialog__header {
  background: #f7f8fa;
  box-shadow: inset 0 -1px 0 0 #ced5e0;
}

::v-deep .el-dialog__body .el-checkbox-group {
  display: flex;
}

::v-deep .el-dialog__body ul:nth-child(1) {
  padding: 0px;
}

::v-deep .el-dialog__body ul li {
  list-style: none;
  margin-bottom: 10px;
}
::v-deep .el-dialog__body ul li span{
  display: inline-block;
    background-color: gainsboro;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: left;
    max-width: 70%;
    margin-top: 5px;
}

.dell ::v-deep .el-button {
  padding: 5px 10px;
  border-radius: 0px;
}

::v-deep .el-date-editor .el-input__icon:nth-child(1) {
  display: none;
}

.biaodan {
  margin: 10px 0;
}

.biaodan
  ::v-deep
  .el-table
  .el-table__header-wrapper
  .el-table__header
  .has-gutter
  tr
  th {
  background: #f4f7fb;
  color: #8da2b5;
}

.biaotou1 {
  position: absolute;
  left: 66px;
  top: 4px;
}

.biaotou {
  position: absolute;
  left: 52px;
  top: 4px;
}

.block {
  display: flex;
  justify-content: end;
}

.block ::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-top: 8px;
}

.block ::v-deep .el-pagination__sizes {
  margin-right: 0;
}

.block ::v-deep .el-pagination__sizes .el-input {
  margin-right: 0;
}

.block ::v-deep .el-pager li,
.block ::v-deep .el-pagination button {
  border: 1px solid #ebebeb;
  background-color: #fff;
  font-weight: initial;
  margin: 0 3px;
}

.time {
  margin-bottom: 0px !important;
}

.time ::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

.mgbo {
  margin-bottom: 5px !important;
}

.mgbo ::v-deep .el-form-item__label {
  padding: 0 !important;
}

.tianjiaziduan {
  display: flex;
  justify-content: end;
}
.biaodan ::v-deep .el-dialog__body{
  padding: 0;
}
.biaodan ::v-deep .el-dialog__body .xxnr {
  height: 500px;
  overflow: scroll;
  padding: 30px 20px;
}
.biaodan .imgwidth{
  width: 70%;
}
.biaodan .imgwidth img{
  width: 100%;
}

.right_kf {
  text-align: end;
}
.imgpopup ::v-deep .el-dialog .el-dialog__body{
  text-align: center;
  padding: 30px 0px !important; 
}
.imgpopup ::v-deep .el-dialog .el-dialog__body img{
  width: 80%;
}
</style>